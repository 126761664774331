<template>
  <div
    class="mx-auto h-full max-w-3xl bg-white p-4 sm:my-8 sm:h-fit sm:rounded-md sm:border sm:border-gray-200 sm:py-8 sm:px-10"
  >
    <div v-if="!submitted">
      <h1 class="text-center text-xl font-bold">Feedback</h1>
      <div class="mt-10">
        Die Anwendung befindet sich in stetiger Weiterentwicklung. <br />
        Wir freuen uns über Rückmeldungen und Verbesserungsvorschläge.
      </div>

      <form class="mt-7 w-full" @submit.prevent="sendFeedback()">
        <textarea v-model="comment" class="mt-3 h-48 w-full" :class="{ 'border-red-200': error }"> </textarea>
        <div>
          <div class="mt-6 sm:mt-5">
            <div class="mt-1 flex flex-col sm:col-span-3 sm:mt-0">
              <div class="mt-5 text-center">
                <label
                  for="file-upload"
                  class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span>Bild / Screenshot hinzufügen</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    accept="images/*"
                    class="sr-only"
                    @change="addPicture"
                  />
                </label>
              </div>
              <div v-if="pictures" class="flex max-w-lg flex-row overflow-auto">
                <img
                  v-for="picture in pictures"
                  :key="picture.id"
                  class="mx-auto mt-5 h-24 w-24 flex-shrink-0 cursor-pointer border border-gray-300 object-contain md:h-32 md:w-32"
                  :src="picture.url"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="error" class="mt-7 rounded-md bg-red-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">Es ist ein Fehler aufgetreten.</h3>
              <div class="mt-2 text-sm text-red-700">
                <ul role="list" class="list-disc space-y-1 pl-5">
                  <li>Das Feedback konnte leider nicht übermittelt werden.</li>
                  <li>Bitte versuchen Sie es später erneut oder wenden Sie sich an Ihren Ansprechpartner.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loading" class="mt-10 flex justify-end">
          <button
            type="button"
            class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="$emit('cancel')"
          >
            Abbrechen
          </button>
          <button
            type="submit"
            class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Absenden
          </button>
        </div>
      </form>
    </div>

    <div v-if="submitted" class="rounded-md bg-green-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div class="ml-3 w-full">
          <h3 class="text-sm font-medium text-green-800">Feedback erfolgreich übermittelt.</h3>
          <div class="mt-2 text-sm text-green-700">
            <p>Vielen Dank für Ihr Feedback.</p>
            <p>Sie werden in {{ forwardIn }} Sekunden automatisch weitergeleitet.</p>
          </div>
          <div class="mt-4 w-full">
            <div class="-mx-1 -my-1.5 flex justify-end">
              <button
                type="button"
                class="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                @click="$emit('cancel')"
              >
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { supabase } from '@/supabase'
  import { defineComponent, reactive, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { XCircleIcon, CheckCircleIcon } from '@heroicons/vue/solid'
  import Compressor from 'compressorjs'

  export default defineComponent({
    components: {
      XCircleIcon,
      CheckCircleIcon,
    },
    emits: ['cancel'],
    async setup(_props, ctx) {
      const router = useRouter()
      let error = ref()
      let comment = ref('')
      let forwardIn = ref(10)
      let loading = ref(false)
      let submitted = ref(false)
      const pictures: { id: number; url: string; file: File }[] = reactive([])

      const sendFeedback = async () => {
        if (loading.value) return
        loading.value = true
        let feedback_id

        const resultInsert = await supabase
          .from('g4y_feedback')
          .insert({ comment: comment.value, url: router.currentRoute.value.fullPath })
        if (resultInsert.error) {
          error.value = resultInsert.error
          console.trace(resultInsert.error)
          loading.value = false
          return
        } else if (resultInsert.data) {
          feedback_id = resultInsert.data[0].id
        }

        for (const picture of pictures) {
          const fileName = `${Math.random()}_${picture.file.name.replaceAll(' ', '_')}`
          const { error: uploadError } = await supabase.storage.from('feedback-picture').upload(fileName, picture.file)
          if (uploadError) {
            error.value = true
            console.error(uploadError)
            loading.value = false
            return
          }
          const { error: pictureDbInsertError } = await supabase
            .from('feedback_picture')
            .insert({ url: fileName, feedback_id })
          if (pictureDbInsertError) {
            error.value = true
            console.error(pictureDbInsertError)
            loading.value = false
            return
          }
        }

        const countDown = () => {
          setTimeout(() => {
            if (forwardIn.value <= 0) {
              comment.value = ''
              forwardIn.value = 10
              loading.value = false
              submitted.value = false
              ctx.emit('cancel')
            } else {
              forwardIn.value--
              countDown()
            }
          }, 1000)
        }
        countDown()
        submitted.value = true
        loading.value = false
      }

      const addPicture = async (e: Event) => {
        //@ts-ignore
        const file = e?.target?.files[0]
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 2048,
          maxHeight: 2048,

          async success(resultFile) {
            if ('name' in resultFile) {
              pictures.push({ id: pictures.length + 1, url: URL.createObjectURL(resultFile), file: resultFile })
            }
          },
          error(err) {
            error.value = err
            console.log(err.message)
          },
        })
      }

      return {
        loading,
        submitted,
        comment,
        error,
        forwardIn,
        sendFeedback,
        router,
        addPicture,
        pictures,
      }
    },
  })
</script>
