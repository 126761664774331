<template>
  <nav :class="{ mobile: isSmallScreen }">
    <router-link v-for="item in navItems" :key="item" :to="item.path">{{ item.name }} </router-link>
  </nav>
</template>

<script setup lang="ts">
  import { useUserDeviceStore } from '@/store/userDevice'
  import { computed } from 'vue'

  const props = defineProps({
    navItems: {
      type: Array,
      default: [],
    },
  })
  const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)
</script>

<style scoped lang="scss">
  nav {
    background-color: var(--neutrals-100);
    box-shadow: 0px 4px 4px var(--neutrals-500);
    display: flex;
    flex-flow: column;
    width: 100%;
    border-radius: 9px;
    margin: 0 10px;
    height: auto;
    align-self: flex-start;
    z-index: 999;

    &.mobile {
      bottom: 75px;
      position: absolute;
      max-width: fit-content;
      left: 7%;
      min-width: 200px;
    }

    a {
      padding-left: 30px;
      border-radius: 9px;
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;

      &.router-link-active {
        background-color: var(--primary-color);
        color: var(--neutrals-100);
        &:hover {
          background-color: var(--secondary-color);
          color: var(--neutrals-900);
        }
      }

      &:hover {
        background-color: var(--secondary-color);
      }
    }
  }
</style>
