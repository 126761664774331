import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import ChangePassword from '@/views/ChangePassword.vue'
import Login from '@/views/Login.vue'
import DeviceNav from '@/views/devices/Nav.vue'
import { supabase } from '@/supabase'
import { useUserSettingsStore } from '@/store/userSettings'
import { useAuthStore } from '@/store/auth'

const LiveTracking = () => import('@/components/LiveTracking.vue')
const Replay = () => import('@/components/Replay.vue')
const SalaryReview = () => import('@/components/SalaryReview.vue')
const DeviceCreate = () => import('@/views/devices/Create.vue')
const DeviceView = () => import('@/views/devices/View.vue')
const DeviceEdit = () => import('@/views/devices/Edit.vue')
const InventoryView = () => import('@/views/inventory/View.vue')
const Order = () => import('@/views/order/index.vue')
const ShopEdit = () => import('@/views/shop/Edit.vue')
const Group = () => import('@/views/shop/Group.vue')
const Site = () => import('@/views/sites/View.vue')
const Sites = () => import('@/views/sites/index.vue')
const Person = () => import('@/views/person/View.vue')
const Cart = () => import('@/views/shop/Cart.vue')
const Home = () => import('@/views/index.vue')
const Notification = () => import('@/views/notification/index.vue')
const PlanOverview = () => import('@/views/dispo/PlanOverview.vue')
const DirvingOrder = () => import('@/views/dispo/DrivingOrder.vue')

const routes: RouteRecordRaw[] = [
  { path: '/live', component: LiveTracking },
  { path: '/login', component: Login },
  { path: '/changePassword', component: ChangePassword },
  { path: '/replay', component: Replay },
  { path: '/replay/:deviceId', component: Replay },
  { path: '/replay/:deviceId/:from', component: Replay },
  { path: '/replay/:deviceId/:from/:to', component: Replay },
  { path: '/salaryReview', component: SalaryReview },
  { path: '/salaryReview/:deviceId', component: SalaryReview },
  { path: '/devices', component: DeviceNav },
  { path: '/devices/create', component: DeviceCreate },
  { path: '/devices/:id', component: DeviceView, props: true },
  { path: '/devices/:id/edit', component: DeviceEdit, props: true },
  { path: '/inventory', component: InventoryView, props: true },
  { path: '/order', component: Order },
  { path: '/shop/edit', component: ShopEdit },
  { path: '/shop', component: Group },
  { path: '/shop/cart', component: Cart },
  { path: '/shop/group', component: Group },
  { path: '/shop/group/:id', component: Group, props: true },
  { path: '/site', component: Site },
  { path: '/sites', component: Sites },
  { path: '/person', component: Person },
  { path: '/home', component: Home },
  { path: '/notification', component: Notification },
  { path: '/plan', component: PlanOverview },
  { path: '/driving-order/:date', component: DirvingOrder, props: true },
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.fullPath.includes('access_token')) {
    const splittedPath = to.fullPath.substring(1).split('&')
    let token_type = ''
    let refreshToken = ''
    let type = ''
    for (const subPath of splittedPath) {
      if (subPath.split('=')[0] === 'refresh_token') refreshToken = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'token_type') token_type = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'type') type = subPath.split('=')[1]
    }
    if (token_type == 'bearer') {
      await supabase.auth.signIn({ refreshToken })
      if (type === 'recovery') {
        next('/changePassword?t=recovery')
      } else if (type === 'invite') {
        next('/changePassword?t=invite')
      }
    }
  } else if (to.fullPath.includes('login')) {
    next()
  } else if (to.fullPath.includes('logout')) {
    supabase.auth.signOut()
    next('/login')
  } else if (supabase.auth.user()?.aud.includes('authenticated')) {
    const authStore = useAuthStore()
    await authStore.fetchUserRights()

    if (to.fullPath === '/') {
      await useUserSettingsStore().fetchUserSettings()
      next(useUserSettingsStore().starting_page)
    } else if ((to.fullPath === '/live' || to.fullPath.startsWith('/devices')) && !authStore.userRights.right_live) {
      next('/')
    } else if (to.fullPath.startsWith('/replay') && !authStore.userRights.right_replay) {
      next('/')
    } else if (to.fullPath.startsWith('/salaryReview') && !authStore.userRights.right_salary_review) {
      next('/')
    } else if (to.fullPath.startsWith('/plan') && !authStore.userRights.right_dispatcher) {
      next('/')
    } else if (to.fullPath.startsWith('/order') && !authStore.userRights.right_dispo_user) {
      next('/')
    } else {
      next()
    }
  } else {
    return next('/login')
  }
})
