import { definitions } from '@/generated/types/database'
import { supabase } from '@/supabase'
import { UserRights } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'

const DEFAULT_USER_RIGHTS: UserRights = {
  right_live: false,
  right_replay: false,
  right_salary_review: false,
  right_devices_create: false,
  right_device_release: false,
  right_alerting: false,
  right_device_operating_hours: false,
  right_dispatcher: false,
  right_dispo_user: false,
}

export const useAuthStore = defineStore('auth', () => {
  let userRights: Ref<UserRights> = ref(DEFAULT_USER_RIGHTS)
  const person: Ref<definitions['person'] | null> = ref(null)

  async function fetchUserRights() {
    console.log('fetchUserRights')
    try {
      const { data, error } = await supabase.from('user_rights').select('*').eq('id', supabase.auth.user()?.id).single()
      if (error) {
        if (
          !(
            error.details &&
            !error.details.localeCompare('Results contain 0 rows, application/vnd.pgrst.object+json requires 1 row')
          )
        )
          throw error
      } else {
        userRights.value = <UserRights>data
      }
    } catch (error) {
      console.trace(error)
    }
  }

  async function fetchPerson() {
    if (!supabase.auth.user()?.id) return
    try {
      const { data, error } = await supabase.from('person').select('*').eq('id', supabase.auth.user()?.id).single()
      if (error) {
        person.value = null
        throw error
      } else {
        person.value = data
      }
    } catch (error) {
      console.trace(error)
    }
  }

  ;(() => {
    console.log('authStore init')
    fetchPerson()
  })()

  supabase.auth.onAuthStateChange(async (event, session) => {
    if (event === 'SIGNED_IN') {
      await fetchUserRights()
      fetchPerson()
    } else {
      console.log('onAuthStateChange', event, session)
      resetUserRightsToDefault()
    }
  })

  function resetUserRightsToDefault() {
    userRights.value = DEFAULT_USER_RIGHTS
  }

  function logout() {
    supabase.auth.signOut()
    userRights.value = DEFAULT_USER_RIGHTS
    person.value = null
  }

  const me = computed<definitions['person'] | null>(() => {
    if (person.value && person.value.id === supabase.auth.user()?.id) {
      return person.value
    }
    return null
  })

  //FIXME fetch User rights
  const user_id = computed(() => {
    console.error('DO NOT USE IT', 'user_id', supabase.auth.user()?.id)
    return supabase.auth.user() ? supabase.auth.user()!.id : undefined
  })

  return { userRights, fetchUserRights, resetUserRightsToDefault, logout, me, user_id }
})
