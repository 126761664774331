import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { ref } from 'vue'

if (import.meta.env.VITE_DEFAULT_STARTING_PAGE === undefined)
  throw new Error('VITE_DEFAULT_STARTING_PAGE is missing in .env')

export const useUserSettingsStore = defineStore(
  'userSettings',
  () => {
    const center = ref([7.77, 49.45])
    let starting_page = ref(import.meta.env.VITE_DEFAULT_STARTING_PAGE)

    async function fetchUserSettings() {
      starting_page.value = import.meta.env.VITE_DEFAULT_STARTING_PAGE
      try {
        const { data, error } = await supabase.from('user_settings').select('starting_page')
        if (error) throw error
        if (data.length > 0 && data[0].starting_page !== null) {
          starting_page.value = data[0].starting_page
        }
      } catch (error) {
        console.error(error)
      }
    }

    ;(async () => {
      await fetchUserSettings()
    })()

    return { center, starting_page, fetchUserSettings }
  },
  {
    persist: true,
  }
)
