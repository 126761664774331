import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserDeviceStore = defineStore('userDevice', () => {
  function helperIsSmallScreen(): boolean {
    return window.matchMedia('(max-width: 640px)').matches
  }

  let isSmallScreen = ref(helperIsSmallScreen())

  function updateSmallScreen() {
    isSmallScreen.value = helperIsSmallScreen()
  }

  return { isSmallScreen, updateSmallScreen }
})
