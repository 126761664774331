import { definitions } from '@/generated/types/database'
import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotificationStore = defineStore('notification', () => {
  let notifications = ref<definitions['notification'][]>([])

  async function fetchNotifications() {
    try {
      const { data, error } = await supabase.from('notification').select('*')
      if (error) throw error
      notifications.value = <definitions['notification'][]>data
    } catch (error) {
      console.error(error)
    }
  }

  ;(() => {
    fetchNotifications()
  })()

  async function markAsRead(id: number) {
    try {
      const { error } = await supabase.from('notification').update({ read: true }).eq('id', id)
      if (error) throw error
      notifications.value.find((n) => n.id === id)!.read = true
    } catch (error) {
      console.error(error)
    }
  }

  return { notifications, markAsRead }
})
