<template>
  <div class="printOverflow flex h-full overflow-hidden">
    <div
      v-if="!isSmallScreen"
      class="noPrint w-full sm:flex sm:w-fit"
      :class="{ flex: mobile_show_sidebar, linkNav: linkNav && isSmallScreen }"
    >
      <div class="flex w-full flex-col sm:w-64" v-if="sideNav">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div v-if="!linkNav" class="flex h-full flex-1 flex-col border-r border-gray-300 bg-gray-100">
          <div class="flex flex-shrink-0 flex-col p-2">
            <slot name="sidebar_top"></slot>
          </div>
          <div class="flex flex-1 flex-col overflow-y-auto pt-2">
            <div class="flex-1">
              <slot name="sidebar">
                <div class="px-2"></div>
              </slot>
            </div>
          </div>
          <div class="flex flex-shrink-0 border-t border-gray-200">
            <slot name="sidebar_bottom"></slot>
          </div>
        </div>
        <SideNav :navItems="sideNavItems" class="sideNav" v-else />
      </div>
    </div>
    <div class="printOverflow flex min-w-0 flex-1 flex-col overflow-hidden" :class="{ isMobile: isSmallScreen }">
      <div class="printOverflow relative z-0 flex flex-1 overflow-hidden">
        <main class="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last" :class="{ page: pageStyle }">
          <slot name="main">main content</slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import SideNav from '@/components/SideNav.vue'
  import { computed } from 'vue'
  import { useUserDeviceStore } from '@/store/userDevice'

  const props = defineProps({
    mobile_show_sidebar: {
      type: Boolean,
      default: false,
    },
    sideNav: {
      type: Boolean,
      default: true,
    },
    sideNavItems: {
      type: Array,
      default: [],
    },
    linkNav: {
      type: Boolean,
      default: false,
    },
    pageStyle: {
      type: Boolean,
      default: false,
    },
  })

  const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)
</script>

<style lang="scss" scoped>
  main {
    height: 100%;

    @media print {
      height: 100%;
    }
  }
  .page {
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
    width: 100%;
    max-width: 1000px;
    border-radius: 9px;
    background-color: var(--neutrals-000);
    margin: auto;
    margin-top: 25px;
  }

  .isMobile {
    .page {
      margin-top: 0;
    }
  }

  .sideNav {
    margin-top: 25px;
  }

  .linkNav {
    width: 0;
  }
</style>
