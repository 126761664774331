<template>
  <span
    v-if="unreadNotifications"
    class="material-symbols-outlined cursor-pointer"
    :class="[unreadNotificationClass]"
    @click="router.push('/notification/')"
  >
    notifications_unread
  </span>
  <span v-else class="material-symbols-outlined cursor-pointer" @click="router.push('/notification/')">
    notifications
  </span>
</template>

<script setup lang="ts">
  import { useNotificationStore } from '@/store/notification'
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'

  const props = defineProps({
    unreadNotificationClass: {
      type: String,
      default: '!text-red-500',
    },
  })

  const router = useRouter()
  const notificationStore = useNotificationStore()

  const unreadNotifications = computed(() => notificationStore.notifications.filter((n) => !n.read).length > 0)
</script>
