<template>
  <div class="flex h-full w-screen justify-around p-10">
    <div>
      <h1 class="text-center text-3xl">Anmelden</h1>
      <form class="mt-10 w-80 text-left" @submit.prevent="login">
        <label for="email" class="mt-5 block text-sm font-medium text-gray-700">E-Mail</label>
        <input
          id="email"
          v-model="email"
          type="email"
          autocomplete="username"
          class="block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
        />
        <label for="pasword" class="mt-5 block text-sm font-medium text-gray-700">Passwort</label>

        <input
          v-model="password"
          type="password"
          autocomplete="current-password"
          class="block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
        />

        <div class="mt-5 rounded-md bg-red-50 p-4" v-if="errorOccured">
          <div class="flex">
            <div class="flex-shrink-0">
              <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">Fehlerhafte Zugangsdaten!</h3>
              <div class="mt-2 text-sm text-red-700">Falsches Passwort oder Nutzer existiert nicht.</div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          :disabled="loading"
          class="mt-5 flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-400"
        >
          Anmelden
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
  import { supabase } from '@/supabase'
  import { defineComponent, Ref, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { XCircleIcon } from '@heroicons/vue/solid'

  export default defineComponent({
    components: { XCircleIcon },
    setup() {
      let password: Ref<string> = ref('')
      let email: Ref<string> = ref('')
      let loading = ref(false)
      let errorOccured = ref(false)
      const router = useRouter()
      const route = useRoute()

      const login = async () => {
        try {
          loading.value = true
          errorOccured.value = false
          const { error } = await supabase.auth.signIn({
            email: email.value,
            password: password.value,
          })

          if (error && error.status === 400 && error.message === 'Invalid login credentials') {
            errorOccured.value = true
            console.log('handle invalid credentials')
          } else if (error) {
            throw error
          } else {
            router.push(route.redirectedFrom?.fullPath != undefined ? route.redirectedFrom?.fullPath : '/')
          }
        } catch (error) {
          console.error(error)
        } finally {
          loading.value = false
        }
      }
      return {
        email,
        password,
        loading,
        errorOccured,
        login,
      }
    },
  })
</script>
