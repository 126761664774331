<template>
  <div class="flex h-full w-screen justify-around p-10">
    <div>
      <h1 class="text-center text-3xl">Passwort ändern</h1>
      <form class="mt-10 w-80 text-left" @submit.prevent="updatePassword">
        <label for="email" class="mt-5 block text-sm font-medium text-gray-700">E-Mail</label>
        <input
          id="email"
          v-model="email"
          type="email"
          disabled
          autocomplete="username"
          class="disabled:border-500 block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:text-gray-400 sm:text-sm"
        />
        <label for="pasword" class="mt-5 block text-sm font-medium text-gray-700">neues Passwort </label>

        <input
          v-model="password"
          type="password"
          autocomplete="new-password"
          class="block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        <button
          type="submit"
          :disabled="loading"
          class="submitButton mt-5 flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-400"
        >
          Passwort aktualisieren
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
  import { supabase } from '@/supabase'
  import { User } from '@supabase/supabase-js'
  import { useToast } from 'primevue/usetoast'
  import { defineComponent, onMounted, Ref, ref } from 'vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    setup() {
      let password: Ref<string> = ref('')
      let email: Ref<string> = ref('')
      let localUser: Ref<User | null> = ref(null)
      let loading = ref(false)
      const router = useRouter()
      const toast = useToast()

      const getUser = async () => {
        try {
          localUser.value = await supabase.auth.user()
          if (localUser.value?.email != undefined) {
            email.value = localUser.value?.email
          }
        } catch (error) {
          console.error(error)
        }
      }

      const updatePassword = async () => {
        try {
          if (localUser.value) {
            loading.value = true
            const { user, error } = await supabase.auth.update({
              email: localUser.value.email,
              password: password.value,
            })
            if (error) throw error
            localUser.value = user
            toast.add({
              severity: 'success',
              summary: 'Passwort geändert',
              detail: 'Ihr Passwort wurde erfolgreich geändert',
              life: 5000,
            })
            router.push('/')
          }
        } catch (error: any) {
          let text = error.message
          if (text == 'New password should be different from the old password.')
            text = 'Das neue Passwort muss sich von dem alten unterscheiden'
          toast.add({
            severity: 'error',
            summary: 'Passwort nicht geändert',
            detail: text,
            life: 10000,
          })
          console.error(error)
        } finally {
          loading.value = false
        }
      }

      onMounted(() => {
        getUser()
      })
      return {
        email,
        password,
        updatePassword,
        loading,
      }
    },
  })
</script>

<style scoped>
  .submitButton {
    font-size: 1rem;
    background-color: var(--primary-color);
    color: #fff;
    &:hover {
      background-color: var(--secondary-color);
    }
  }
</style>
